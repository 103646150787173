import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DebuggerService, LogType } from 'src/app/services/debugger.service';
import { LoadingSplashComponent } from "../../../navigation/menu/loading-splash/loading-splash.component";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-login-passwordless',
    standalone: true,
    templateUrl: './passwordless.component.html',
    styleUrl: './passwordless.component.scss',
    imports: [CommonModule, LoadingSplashComponent]
})
export class PasswordlessComponent  implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  success: Boolean = false;
  loading: Boolean = true;

  constructor(
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute){
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    DebuggerService.Log(LogType.Authentication, "PasswordlessComponent.ngOnInit", "Init Passwordless");

    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
          const signature = params['signature'];
          DebuggerService.Log(LogType.Authentication, "PasswordlessComponent.ngOnInit", "Signature: " + signature);

          if(signature == null || signature == undefined){
            this.success = false;
            this.loading = false;
            return;
          }

          this.auth.PasswordlessLogin(signature).then(r => {
            DebuggerService.Log(LogType.Authentication, "PasswordlessComponent.ngOnInit", "Link request complete");

            this.success = r;
            this.loading = false;
          }, error => {
            DebuggerService.Log(LogType.Authentication, "PasswordlessComponent.ngOnInit", "Link request FAILED!");

            this.success = false;
            this.loading = false;
          })
        })
      );
  }
}