<div id="container">
  <mat-card class="mfa-card">
      <mat-card-content>

        <div class="title" translate>MFA.TITLE</div>
        <div>{{ 'MFA.EXPLANATION' | translate }}</div>
        <div>{{ 'MFA.CONTACTPATH' | translate : { phoneNumber:(authService.CurrentAuthenticationState?.MFAState?.contactInformation ?? '--') } }}</div>

        <mat-form-field class="field" appearance="outline" [style.width.%]=100>
          <mat-label>{{ 'MFA.HINT' | translate }}</mat-label>
          <input [readonly]="lockForm" [(ngModel)]="enteredCode" matInput placeholder="########">
          <mat-icon matSuffix>phone_android</mat-icon>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions class="button-row">
        <button [disabled]="lockForm" (click)="validateMFA()" mat-raised-button color="primary">{{ 'COMMON.OK' | translate }}</button>
        &nbsp;<mat-spinner [style.width.px]=25 [style.height.px]=25 *ngIf="lockForm == true" class="loading"></mat-spinner>
        <div *ngIf="mfaResult != null">{{ (mfaResult == true ? 'MFA.SUCCESS' : 'MFA.FAIL') | translate }}</div>
      </mat-card-actions>

    </mat-card>
</div>