import { Component, OnDestroy, OnInit } from '@angular/core';
import { TopMenuComponent } from './navigation/menu/top-menu/top-menu.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SideMenuComponent } from './navigation/menu/side-menu/side-menu.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { environment } from 'src/environments/environment.development';
import { TranslationService } from './services/translation.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { AuthenticationState } from './services/authentication/AuthenticationState';
import { DebuggerService, LogType } from './services/debugger.service';
import { LoadingSplashComponent } from "./navigation/menu/loading-splash/loading-splash.component";
import { CommonModule } from '@angular/common';
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root-component',
  standalone: true,
  imports: [TopMenuComponent, SideMenuComponent, FooterComponent, RouterModule, CommonModule, LoadingSplashComponent], 
  templateUrl: './app.root.component.html',
  styleUrls: ['./app.root.component.scss'],
  providers: [TranslationService]
})
export class AppRootComponent implements OnInit, OnDestroy { 
  
  authState: AuthenticationState | null  = null;
  enviromentName: string = environment.Name;

  private subs: Subscription[] = [];

  constructor(
    private translationService: TranslationService, 
    private route: ActivatedRoute, 
    private router: Router,
    private authService: AuthenticationService
  ) {

    translationService.RestoreLanguage();

    this.subs.push(route.queryParams.subscribe(
      data => this.translationService.SetLanguage(data['lang'])
    ))

    this.subs.push(this.authService.AuthenticationState$.subscribe(authState => {
      this.authState = authState;
      if(authState != null 
        && authState.User == null
        && authState?.IsLoggedIn == false 
        && authState?.IsLoading == false)
      {
        var url = window.location.pathname;
        DebuggerService.Log(LogType.Authentication, "AppRootComponent", "URL: " + JSON.stringify(url));
                
        if(this.checkIfStringStartsWith(url, NavigationService.NoLoginRoutes)){
          DebuggerService.Log(LogType.Authentication, "AppRootComponent", "Don't log in.");
        }else{
          DebuggerService.Log(LogType.Authentication, "AppRootComponent", "trigger login: " + url);
          var paramUrl = new URL(window.location.href);
          var user_hint = paramUrl.searchParams.get("login_hint");
          var use_passwordless = paramUrl.searchParams.has("pl");
          
          DebuggerService.Log(LogType.Authentication, "AppRootComponent", "User login hint provided: " + JSON.stringify(user_hint));

          this.authService.Login(url, user_hint ?? "", use_passwordless);
        }
      }
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  checkIfStringStartsWith(str : string, substrs: string[]) {
    return substrs.some(substr => str.toLowerCase().startsWith(substr.toLowerCase()));
  }
}