import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationState } from 'src/app/services/authentication/AuthenticationState';
import { LoadingSplashComponent } from 'src/app/navigation/menu/loading-splash/loading-splash.component';


@Component({
    selector: 'app-start',
    standalone: true,
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss'],
    imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, LoadingSplashComponent]
})
export class StartComponent implements OnInit, OnDestroy { 

  private subs: Subscription[] = [];
  authState: AuthenticationState | null  = null;

  constructor(private authService: AuthenticationService){}
  
  ngOnInit(): void {
    this.subs.push(this.authService.AuthenticationState$.subscribe(authState => {
      this.authState = authState;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}