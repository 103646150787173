import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BelayAccountCoreClient, TenantViewModel } from '.swagger/Belay.Core.API';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DebuggerService, LogType } from 'src/app/services/debugger.service';

@Component({
  selector: 'app-menu-top-tenant-picker',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule],
  templateUrl: './tenant-picker.component.html',
  styleUrls: ['./tenant-picker.component.scss'],
  providers: [BelayAccountCoreClient]

})
export class TenantPickerComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  
  public tenants: TenantViewModel[] | null = null;
  public currentTenantId: string | null = null;

  constructor(private accountAPI: BelayAccountCoreClient, public authService: AuthenticationService){}
  
  ngOnInit(): void {
    this.authService.AwaitedIsLoggedIn.then( _ => {
      this.subs.push(
        this.accountAPI.tenants().subscribe(tenants => {
          this.tenants = tenants;
            DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.ngOnInit", "User selectable tennants -> " + JSON.stringify(this.currentTenantId));

            var tokenTennant = this.authService?.CurrentAuthenticationState?.Metadata?.tenant?.id ?? null;
            DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.ngOnInit", "Token Tennant: " + tokenTennant);

            this.currentTenantId = this.tenants?.find(f => f.id == this.authService?.CurrentAuthenticationState?.Metadata?.tenant?.id)?.id ?? null;
            DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.ngOnInit", "Found tennant: " + this.currentTenantId);
          })
        )
    });
  }

  tenantChanged(newTenant: string){
    DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.tenantChanged", "Changed to:  " + newTenant);
    this.authService.ChangeTenant(newTenant).then(result => {
      DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.tenantChanged", "Tenant change success: " + result);
    })

    DebuggerService.Log(LogType.Tennant, "TenantPickerComponent.tenantChanged", "Current tennant: "+  this.currentTenantId);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
