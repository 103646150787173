<button id="profileButton" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Profile button">
  <mat-icon>person</mat-icon>
</button>

<mat-menu #menu="matMenu">
    <div id="userName">{{user?.name ?? "Okänd"}}</div>
    <button mat-menu-item routerLink="/profile">
      <mat-icon>person</mat-icon>
      <span>Profil</span>
    </button>
  
    <button  mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <div>Logga ut</div>
    </button>
</mat-menu>