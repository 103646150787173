
<mat-accordion *ngIf="hasChildren()">
    <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>{{MenuItem?.Icon}}</mat-icon>{{'MENU.' + MenuItem?.Name | translate}} <mat-icon class="chevron">{{ panelOpenState ? "keyboard_arrow_down" : "keyboard_arrow_right"}}</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let link of getLinks()">
            <app-side-menu-item-link [MenuItem]="link" [User]="User" />
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div class="mat-expansion-panel mat-accordion" *ngIf="hasChildren() == false && (MenuItem?.RequireLogin == false || User != null)" >
  <app-side-menu-item-link class="mat-expansion-panel-header" [MenuItem]="MenuItem" [User]="User" />
</div>