export const environment = {
    'Debug': true,
    "DebugTypesActive": [ 0, 10, 20, 30, 40, 50, 60, 70 ], 
    'Name': 'Development',
    'BASE_URL': 'https://belay.dev.infria.se',
    'API_BASE_URL': 'https://dev-api.belay.se',
    'ApplicationInsights':{
        'ConnectionString': 'InstrumentationKey=95a36ec2-7cd2-414d-9af9-38af01bf151e;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/'
    },
    'Auth0':{
        'Domain': 'https://dev-auth.belay.se',
        'Client_Id': 'qFV5TTcqXBZ4cfkEEpDlf5dOABaAmvtU', // dev: qFV5TTcqXBZ4cfkEEpDlf5dOABaAmvtU // local: 1pMN4J4t9jZKxMqnj9yWP1o57Mnmh0jw
        'Redirect_URI': window.location.origin, 
        'Use_Refresh_Tokens': true
    }
};