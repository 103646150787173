import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, IdToken, User } from "@auth0/auth0-angular";
import { Observable, Subject,  Subscription, map, take } from "rxjs";
import { BelayAccountCoreClient, BelayMFACoreClient, EntityInputModel, MFAStateViewModel, MFAStatus } from ".swagger/Belay.Core.API";
import { AuthenticationState } from "./AuthenticationState";
import { BelayIdentityTokenModel } from "./BelayJWTTokenModel";
import { HttpErrorResponse } from "@angular/common/http";
import { DebuggerService, LogType } from "../debugger.service";

@Injectable({
    providedIn: 'root', 
})
export class AuthenticationService {

    public AuthenticationState$: Observable<AuthenticationState>;
    public CurrentAuthenticationState: AuthenticationState | null;
    private authenticationStateSubject: Subject<AuthenticationState> | null = null ;
  
    private subs: Subscription[] = [];
    
    private router: Router = inject(Router);
    private authService: AuthService = inject(AuthService);
    
    private user: User | null = null;

    //private accessToken: string | null = null;
    private idToken: string | null = null;
    private idTokenParsed: BelayIdentityTokenModel | null  = null;

    private mfaState: MFAStateViewModel | null = null;

    private authState: AuthenticationState | null = null;

    private isLoading = true;
    private idTokenCallbackCalledOnce = false;

    private scopes = "openid name email picture roles app_metadata user_metadata offline_access";

    constructor(private mfaService : BelayMFACoreClient, private accountClient: BelayAccountCoreClient){
        this.CurrentAuthenticationState = null;
        this.authenticationStateSubject = new Subject<AuthenticationState>();
        this.AuthenticationState$ = this. authenticationStateSubject.asObservable();
        this.AuthenticationState$.subscribe(state => 
            {
                this.CurrentAuthenticationState = state;
                DebuggerService.Log(LogType.Authentication, "AuthenticationService", "Current Authentication state: " + JSON.stringify(state));
            }
        );
    }

    /** 
     * Used in factory to start this service.
     * Only called once.
     **/
    init() {
        this.idToken = null;
        this.isLoading = true;

        this.subs.push(this.authService.user$.subscribe(user => { this.handleAuthenticationCallback(user) }));
        this.subs.push(this.authService.idTokenClaims$.subscribe(claims => this.handleIdTokenCallback(claims ?? null)));
        this.subs.push(this.authService.isLoading$.subscribe(loading => {            
            this.isLoading = loading;
            this.pushNewAuthenticationUpdate();
        }));
        
        this.authService.handleRedirectCallback();

        DebuggerService.Log(LogType.Authentication, "AuthenticationService.Init", "Complete");
    }
    
    /** Login the current user. */
    Login(redirectPath: string, hint: string = "", passwordless = false): void {
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.Login", "login with redirect: " + redirectPath);
        this.authService.loginWithRedirect({
            appState: { target: redirectPath },
            authorizationParams: {
                scope: this.scopes,
                login_hint: hint,
                connection: passwordless == true ? "email" : undefined
            }
        });
    }
    
    /** Log out current user and clear tokens. */
    async Logout(): Promise<void> {
        var url = '/';

        await this.authService.logout({
          openUrl(url) {
            window.location.replace(url);
          }
        });

        this.user = null;
        this.authState = null;
        this.mfaState = null;
        this.idToken = null;
        this.idTokenParsed = null;

        this.authService.logout();
    }
    
    /** Validate provided user Code with server MFA service. */
    ValidateMFA(code: string): Promise<boolean>{
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.ValidateMFA", "ValidateMFA.");
        return new Promise<boolean>(resolve => {
            this.mfaService.verifyCode(code).subscribe(mfaStateResponse => {
            
                this.mfaState = mfaStateResponse;
                this.pushNewAuthenticationUpdate();
                this.checkMFA();
    
                resolve(this.mfaState.status == MFAStatus.Valid);
            });
        });
    }
    
    /** Initiate tennant change on user request */
    ChangeTenant(newTenant: string) : Promise<Boolean> {
        DebuggerService.Log(LogType.Tennant, "AuthenticationService.ChangeTenant", "Change tenant started");
        return new Promise<boolean>(resolve => {
            this.accountClient.changetenant( { id: newTenant } as EntityInputModel ).subscribe( _ => {
                DebuggerService.Log(LogType.Tennant, "AuthenticationService.ChangeTenant", "Change request made successfully.");
                this.accountClient.login().subscribe( _ => {
                    this.updateToken().then(() => {
                        DebuggerService.Log(LogType.Tennant, "AuthenticationService.ChangeTenant", "Token updated successfully.");
                        resolve(true);
                    });
                });
            }, (error:HttpErrorResponse) => {
                DebuggerService.Log(LogType.Tennant, "AuthenticationService.ChangeTenant", "Token updated failed.");
                resolve(false);
            });
        });
    }

    PassewordLessInit(): Promise<Boolean>{
        return new Promise<Boolean>(resolve => {
            this.authService.loginWithRedirect()
        });
    }

    /** Attempt passwordless login */
    PasswordlessLogin(signature: string): Promise<Boolean>{
        return new Promise<Boolean>(resolve => {
            this.accountClient.requestmagiclink(signature).subscribe(res =>{
                resolve(true);
            }, 
            error => {
                DebuggerService.Log(LogType.Authentication, "AuthenticationService.PasswordlessLogin", "Failed to generate magic string: " + JSON.stringify(error));
                resolve(false);
            })
        });
    }

    get AwaitedLoadingComplete(): Promise<boolean>{
        let loadingCompletePromise: Promise<boolean> = new Promise((resolve, reject) => {

            if(this.CurrentAuthenticationState?.IsLoading == false){
                resolve(true)
                return;
            }

            let sub = this.AuthenticationState$.subscribe(async state => {
                if(state.IsLoading == false){
                    resolve(true);
                    sub.unsubscribe();
                }
            })
        });

        return loadingCompletePromise;
    }

    get AwaitedIsLoggedIn(): Promise<boolean>{
        let loginCompletePromise: Promise<boolean> = new Promise((resolve, reject) => {
            let sub = this.AuthenticationState$.subscribe(async state => {
                if(state.IsLoading == false){
                    while(this.IsLoggedIn == false){
                        await this.delay(100);
                    }
                    resolve(this.IsLoggedIn);
                    sub.unsubscribe();
                }
            })
        });

        return loginCompletePromise;
    }
 
    /** Is used logged in right now. */
    get IsLoggedIn(): boolean {
        return this.authState?.IsLoggedIn ?? false;
    }

    /** User is logged in and has passed MFA. */
    get IsAuthenticated(): boolean {
        return this.authState?.IsAutenticated ?? false;
    }

    /** Get current MFA state. */
    get MFAState(): MFAStateViewModel | null{
        return this.authState?.MFAState ?? null;
    }

    /** returns current Id token. */
    get IdToken(): string | null {
        return this.idToken ?? null;
    }

    /** Returns metadata about user */
    get UserMetadata(): BelayIdentityTokenModel | null{
        return this.idTokenParsed;
    }

    private handleAuthenticationCallback(user: User | null | undefined): void {
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.handleAuthenticationCallback", "handleAuthenticationCallback.");
        if(user){
            this.user = user;       
            this.updateToken();
        }else{
            this.user = null;
        }

        this.pushNewAuthenticationUpdate();
    }

    private handleIdTokenCallback(claims: IdToken | null){
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.handleIdTokenCallback", "handleIdTokenCallback.");
        DebuggerService.Log(LogType.TokenData, "AuthenticationService.handleIdTokenCallback", "Token data -> " + JSON.stringify(claims));
        
        this.idTokenCallbackCalledOnce = true;
        if(claims != null && this.idToken != null){
            this.checkMFA();
        }else{
            this.pushNewAuthenticationUpdate();
        }
    }

    private checkMFA(){
        DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "Start MFA Check.");
        this.AwaitedLoadingComplete.then( isLoadingComplete => {
            DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "Done waiting for login.");
            if(isLoadingComplete == true){
                this.mfaService.state().subscribe(mfaState => {
                    DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "Received MFA State -> " + JSON.stringify(mfaState));

                    this.mfaState = mfaState;

                    // mfa is already valid
                    if(mfaState.status == MFAStatus.Valid){
                        DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "MFA is Valid.");
                        this.updateToken();
                    }

                    // do update contact info
                    if(mfaState.status == MFAStatus.NeedContactInformation){
                        DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "Require MFA update contact info.");
                    }

                    // perform MFA check
                    if( mfaState.status == MFAStatus.Required){
                        DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "Require MFA Validation.");
                        this.router.navigate(['/mfa']);
                    }

                    // block and require re-login.
                    if(mfaState.status == MFAStatus.Invalid){
                        DebuggerService.Log(LogType.MFA, "AuthenticationService.checkMFA", "MFAStatus == Invalid.");
                        this.router.navigateByUrl('access-denied');
                    }

                    this.pushNewAuthenticationUpdate();
                })
            }
        });
    }

    /**
     * called when we want to get new ID token data, such as when we change tennant or mfa state changes.
     * @returns Promised based on if the token could be updated.
     */
    private updateToken(): Promise<boolean>{
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.updateToken", "updateToken.");

        return new Promise<boolean>(resolve => {
            this.authService.getAccessTokenSilently(
                {
                    detailedResponse: true,
                    authorizationParams: {
                        scope: this.scopes
                    }
                }
            ).subscribe(token => {
                this.idToken = token?.id_token ?? null;
                DebuggerService.Log(LogType.TokenData, "AuthenticationService.updateToken", this.idToken);
                if(token?.id_token != null){
                    this.idTokenParsed = JSON.parse(atob(token.id_token.split('.')[1])) as BelayIdentityTokenModel;
                    DebuggerService.Log(LogType.TokenData, "AuthenticationService.updateToken", "IDToken Parsed -> " + JSON.stringify(this.idTokenParsed));
                    DebuggerService.Log(LogType.Tennant, "AuthenticationService.updateToken", "Token tennant data -> " + JSON.stringify(this.idTokenParsed.tenant));
                    resolve(true);
                }else{
                    resolve(false);
                }
            },(error:HttpErrorResponse) => {
                DebuggerService.Log(LogType.Authentication, "AuthenticationService.updateToken", JSON.stringify(error));
                resolve(false);
            });
        })
       
    }

    private pushNewAuthenticationUpdate(){
        this.authState = new AuthenticationState(this.user, this.idTokenParsed, this.mfaState, !(this.isLoading == false && this.idTokenCallbackCalledOnce== true));
        DebuggerService.Log(LogType.Authentication, "AuthenticationService.pushNewAuthenticationUpdate", "Pushing new Authentication Update");
        this.authenticationStateSubject?.next(this.authState);
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }
}