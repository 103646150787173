import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
  })
export class TranslationService  {
    constructor(private translate: TranslateService){
        translate.addLangs(['sv', 'en']);
        translate.setDefaultLang('sv');

        this.RestoreLanguage();
    }
 
    public GetLanguages(): string[] {
        return this.translate.langs;
    }

    public GetCurrentlySelectedLanguage(): string {
        return this.translate.currentLang;
    }

    public SetLanguage(lang: string){
        if(lang != null && this.translate.langs.includes(lang)){
            this.translate.use(lang);
            localStorage.setItem('language',lang);
        }
    }

    public RestoreLanguage(){
        const lang =  localStorage.getItem('language');;
        if(lang != null && this.translate.langs.includes(lang)){
            this.translate.use(lang);
        }else{
            this.translate.use('sv');
        }
    }
}