import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationService } from 'src/app/services/translation.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-language-picker',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, TranslateModule],
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {
  langs: string[] = [];
  constructor(private translateService: TranslationService){
    this.langs = translateService.GetLanguages();
  }

  setLanguage(lang: string){
    this.translateService.SetLanguage(lang);
  }
}
