import { MFAStateViewModel, MFAStatus } from ".swagger/Belay.Core.API";
import { User } from "@auth0/auth0-angular";
import { BelayIdentityTokenModel } from "./BelayJWTTokenModel";

export class AuthenticationState{

    /**
     * The current state of authentication and login.
     * @param user The currently logged in user.
     * @param userMetadata jwt parsed metadata.
     * @param mfa mfa state
     * @param isLoading is loading of auth state complete?
     */

    constructor(
        private user: User | null,  
        private userMetadata : BelayIdentityTokenModel | null,
        private mfa: MFAStateViewModel | null,
        private isLoading: boolean
    ) {}
    
    /** Is the user identified and has passed first login stage (this being true does not mean they have completed MFA). */
    get IsLoggedIn(): boolean { 
        //var tokenExpired = (Math.floor((new Date).getTime() / 1000)) >= (this.User?.exp ?? -1)
        return this.User != null; // && tokenExpired == false;
    }

    /** If this is true the user has completed MFA */
    get IsMFAComplete(): boolean {
        return this.mfa?.status == MFAStatus.Valid;
    }

    /** 
     * True if the user is identified and has valid MFA.
     * Before this is 'true' no real interaction can (or should) be made with the system.
     */
    get IsAutenticated() : boolean { 
        return this.IsLoggedIn && this.IsMFAComplete
    }

    /** Returns false when loading is complete, dont redirect user based on auth before this is false. */
    get IsLoading(): boolean{
        return this.isLoading;
    }

    /** The user at this time. May be null.*/
    get User(): User | null { return this.user; }

    /** The current MFA state at this time. May be null. */
    get MFAState(): MFAStateViewModel | null { return this.mfa; }

    get Metadata(): BelayIdentityTokenModel | null { return this.userMetadata; }
}
