import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../../../services/navigation.service';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { LoginButtonComponent } from "./login-button/login-button.component";
import { ProfileButtonComponent } from './profile-button/profile-button.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { TenantPickerComponent } from './tenant-picker/tenant-picker.component';
import { AuthenticationState } from 'src/app/services/authentication/AuthenticationState';

@Component({
    selector: 'app-navigation-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
    standalone: true,
    providers: [NavigationService],
    imports: [
      TranslateModule, LoginButtonComponent, ProfileButtonComponent, 
      LanguagePickerComponent, MatToolbarModule, MatButtonModule, MatIconModule, 
      CommonModule, RouterOutlet, RouterLink, RouterLinkActive,
      TenantPickerComponent
    ]
})
export class TopMenuComponent implements OnInit, OnDestroy { 
  private subs: Subscription[] = [];
  authState: AuthenticationState | null  = null;

  constructor(private authService: AuthenticationService){}
  
  ngOnInit(): void {
    this.subs.push(this.authService.AuthenticationState$.subscribe(authState => {
      this.authState = authState;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}