
<a
    *ngIf="MenuItem?.isInternal && MenuItem?.IsHiddenInMenu == false && !(MenuItem?.RequireLogin == true && User == null)" 
    routerLink="{{MenuItem?.FullURL}}" [routerLinkActive]="['active']" 
    class="menuItem"  mat-fab-flat extended>
        <mat-icon>{{MenuItem?.Icon}}</mat-icon><span>{{'MENU.' + MenuItem?.Name | translate}}</span>
</a>

<a class=""
    *ngIf="!MenuItem?.isInternal && MenuItem?.IsHiddenInMenu == false && !(MenuItem?.RequireLogin == true && User == null)" 
    href="{{MenuItem?.FullURL}}" [routerLinkActive]="['active']" 
    class="menuItem"  mat-fab-flat extended>
        <mat-icon>{{MenuItem?.Icon}}</mat-icon><span>{{'MENU.' + MenuItem?.Name | translate}}</span> 
</a> 