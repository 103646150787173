import { Type } from "@angular/core";

export class MenuItem {
  
    private _name: string;
    private _relativeURL: string ;
    private _parent: MenuItem | null = null;
    private _fullURL: string = "";
    private _component: Type<any> | null;
    private _icon: string;
    private _requireLogin = false;
    private _subPages: MenuItem[];
    private _isHiddenInMenu:boolean = false;

    constructor(name: string, component: Type<any>| null, url: string, icon: string, requireLogin = false, subPages: MenuItem[] = [], isHiddenInMenu: boolean = false){
        this._name = name;
        this._relativeURL = url;
        this._component = component;
        this._icon = icon;
        this._requireLogin = requireLogin;
        this._subPages = subPages;
        this._isHiddenInMenu = isHiddenInMenu;
    }

    public get Name(): string{
        return this._name;
    }

    public get Icon(): string{
        return this._icon;
    }

    public get isInternal(): boolean{
        return this._component != null;
    }

    public get RelativeURL(): string {
        return this._relativeURL;
    }

    public get FullURL(): string {
        return this._fullURL;
    }

    public get Parent(): MenuItem | null{
        return this._parent ;
    }

    public get TopParent(): MenuItem {
        return this.GetTopParent(this);
    }

    public get Component(): Type<any> | null{
        return this._component;
    }

    public get RequireLogin(): boolean {
        return this._requireLogin;
    }

    public get IsHiddenInMenu(): boolean {
        return this._isHiddenInMenu;
    }

    public get SubPages(): MenuItem[]{
        return this._subPages;
    }

    UpdateCompleteURL(path: string) {
        this._fullURL = path;
    }

    UpdateParentMenuItem(parent: MenuItem | null){
        this._parent = parent;
    }
    
    private GetTopParent(menuItem: MenuItem | null = null): MenuItem {
        let result = null;

        if (this.Parent == null) {
            result = this;
        } else {
            result = this.GetTopParent(this.Parent);
        }
    
        return result;
    }
  } 