import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuItem } from '../../MenuItem';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { SideMenuLinkComponent } from './link/link.component';

@Component({
  selector: 'app-side-menu-item',
  standalone: true,
  imports: [
    CommonModule, MatExpansionModule, MatIconModule, 
    TranslateModule, RouterLinkActive, RouterLink, SideMenuLinkComponent], 
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})

export class SideMenuItemComponent {

  @Input() MenuItem: MenuItem | null = null;
  @Input() User: User | null = null;
  
  panelOpenState = false;

  hasChildren(): boolean {
    if(this.MenuItem?.SubPages == null)
      return false;

    return this.MenuItem?.SubPages?.length > 0
  }

  getLinks(): MenuItem[] {
    if(this.MenuItem == null  || this.MenuItem?.SubPages == null)
      return [];
    
    return this.MenuItem.SubPages.filter(i => i.SubPages == null || i.SubPages.length == 0);
  }

  getSubExpanders(): MenuItem[]{
    if(this.MenuItem == null  || this.MenuItem?.SubPages == null)
      return [];
    
    return this.MenuItem.SubPages.filter(i => i.SubPages != null && i.SubPages.length > 0);
  }
}