import { environment } from "src/environments/environment";

export class DebuggerService {
    public static Log(type: LogType, source: string, value: string){
        var typeNum: number = type;
        if(environment.Debug == true && environment.DebugTypesActive.indexOf(typeNum) > -1){
            var date = new Date();
            var timestamp=  date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + ("0" + date.getHours() ).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2) ;
            console.log(timestamp + " (" + (source?? "[UNKNOWN]") + "): " + value ?? "[NULL]");

            // Could log to some central system here too if wanted, for collecting logs from external users centrally.
        }
    }
}

export enum LogType {
    Unkown = 0,
    General = 10,
    Authentication = 20,
    MFA = 30,
    Tennant = 40,
    Navigation = 50,
    ApplicationInsights = 60,
    TokenData = 70
}