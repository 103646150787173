import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { AuthenticationState } from 'src/app/services/authentication/AuthenticationState';

@Component({
  selector: 'app-navigation-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy { 
  private subs: Subscription[] = [];
  authState: AuthenticationState | null  = null;

  constructor(private authService: AuthenticationService){}
  
  ngOnInit(): void {
    this.subs.push(this.authService.AuthenticationState$.subscribe(authState => {
      this.authState = authState;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
