import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationState } from 'src/app/services/authentication/AuthenticationState';
import { BelayCustomersCoreClient } from '.swagger/Belay.Core.API';
import { DebuggerService, LogType } from 'src/app/services/debugger.service';

@Component({
  selector: 'app-mfa',
  standalone: true,
  imports: [
    TranslateModule, CommonModule, MatCardModule, MatDividerModule, MatButtonModule,
    MatFormFieldModule, MatInputModule, MatIconModule, FormsModule, MatProgressSpinnerModule
  ],
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit, OnDestroy { 
  private subs: Subscription[] = [];
  authState: AuthenticationState | null  = null;

  enteredCode: string = "";
  lockForm = false;
  mfaResult: boolean | null = null;
  showMFA = false;
  
  constructor(public authService: AuthenticationService, private router: Router){}

  ngOnInit(): void {
    if(this.authService.CurrentAuthenticationState?.IsLoading == false && this.authService.CurrentAuthenticationState?.IsLoggedIn && this.authService.CurrentAuthenticationState?.IsMFAComplete == false){
      this.showMFA = true;
    }else if(this.authService.CurrentAuthenticationState?.IsLoading == false && this.authService.CurrentAuthenticationState?.IsLoggedIn && this.authService.CurrentAuthenticationState?.IsMFAComplete == true){
      //this.router.navigate(['/']);
    }else if(this.authService.CurrentAuthenticationState?.IsLoading == false){
      //this.router.navigate(['/']);
    }
  }

  validateMFA(){
    this.lockForm = true;
    this.mfaResult = null;

    DebuggerService.Log(LogType.MFA, "MfaComponent","validate code: " + this.enteredCode);
    this.authService.ValidateMFA(this.enteredCode).then(resp => {
      DebuggerService.Log(LogType.MFA, "MfaComponent","mfa ok? -> "  + resp);
      this.lockForm = false;
      this.mfaResult = resp;

      if(resp == true){
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}