import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BelayAccountCoreClient, BelayTagsCoreClient } from '.swagger/Belay.Core.API';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DebuggerService, LogType } from 'src/app/services/debugger.service';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers:[BelayTagsCoreClient, BelayAccountCoreClient]
})
export class UsersComponent implements OnInit {
  


  constructor(
    private tagsClient: BelayTagsCoreClient,
    private accountClient: BelayAccountCoreClient,
    private authService: AuthenticationService
  ){}

  ngOnInit(): void {
    this.accountClient.tenants().subscribe(t => {
      DebuggerService.Log(LogType.General, "UsersComponent", JSON.stringify(t));
    })
  }
}
