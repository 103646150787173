import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from '../../../MenuItem';
import { User } from '@auth0/auth0-angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-side-menu-item-link',
  standalone: true,
  imports: [
    CommonModule, MatIconModule, 
    TranslateModule, RouterLinkActive, RouterLink
  ], 
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class SideMenuLinkComponent {
  @Input() MenuItem: MenuItem | null = null;
  @Input() User: User | null = null;
}
