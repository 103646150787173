/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { HttpClient, HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRootComponent } from './app/app.root.component';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app.route';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, inject } from '@angular/core';
import { VERSION as CDK_VERSION } from '@angular/cdk';
import { VERSION as MAT_VERSION, MatNativeDateModule } from '@angular/material/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsightsService } from './app/services/application-insights.service';
import { AuthModule } from '@auth0/auth0-angular';

import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationService } from './app/services/authentication/authentication.service';

import { API_BASE_URL, BelayAccountCoreClient, BelayMFACoreClient } from '../.swagger/Belay.Core.API'
import { environment } from './environments/environment';
import { DebuggerService, LogType } from './app/services/debugger.service';

/* eslint-disable no-console */
if(environment.Debug){
  DebuggerService.Log(LogType.General, "Main", 'Angular CDK version: ' +  CDK_VERSION.full);
  DebuggerService.Log(LogType.General, "Main", 'Angular CDK version: ' + CDK_VERSION.full);
  DebuggerService.Log(LogType.General, "Main", 'Angular Material version: ' + MAT_VERSION.full);
  DebuggerService.Log(LogType.General, "Main", 'Environment: ' + environment.Name);
  DebuggerService.Log(LogType.General, "Main", 'Base URL: ' + environment.BASE_URL);
  DebuggerService.Log(LogType.General, "Main", 'API URL: ' + environment.API_BASE_URL);
  DebuggerService.Log(LogType.General, "Main", 'Active debug levels: ' + JSON.stringify(environment.DebugTypesActive));
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function MFAClientFactory(client: HttpClient){
  const baseUrl = inject(API_BASE_URL);
  DebuggerService.Log(LogType.MFA, "Main", "MFAClientFactory: " + baseUrl);
  return new BelayMFACoreClient(client, baseUrl);
}

export function AccountClientFactory(client: HttpClient){
  const baseUrl = inject(API_BASE_URL);
  DebuggerService.Log(LogType.Authentication, "Main", "BelayAccountCoreClientFactory: " + baseUrl);
  return new BelayAccountCoreClient(client, baseUrl);
}

// creates simple handler that replaces unknown translations with an error message.
export class BelayMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
      return '[Unknown translation: "' + params.key + '"]';
  }
}

// adds bearer token to all requests if user is logged in.
export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService: AuthenticationService = inject(AuthenticationService);
  if(authService.IsLoggedIn == true)
  {
    var tokenReq = request.clone({
        setHeaders: { Authorization: `Bearer ${authService.IdToken}` },
    });
        
    return next(tokenReq);
  } else {
    return next(request);
  }
}

bootstrapApplication(AppRootComponent, {
      providers:[
        provideRouter(APP_ROUTES),
        provideAnimations(),
        provideHttpClient(
          withInterceptors([authInterceptor]),
        ),
        {
          provide: API_BASE_URL, useValue: environment.API_BASE_URL
        },
        importProvidersFrom(MatNativeDateModule), 
        
        ApplicationInsightsService,
        {
          provide: ErrorHandler,
          useClass: ApplicationinsightsAngularpluginErrorService,
        },

        {
          provide: APP_INITIALIZER,
          deps: [ApplicationInsightsService],
          useFactory: (appInsights: ApplicationInsightsService) => {
            return () => {
              appInsights.init();
              return;
            };
          },
          multi: true,
        },

        importProvidersFrom(TranslateModule.forRoot( {  
          loader: {
            provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          },
          missingTranslationHandler: {provide: MissingTranslationHandler, useClass: BelayMissingTranslationHandler},
          useDefaultLang: false
        })),

        importProvidersFrom(AuthModule.forRoot({
          domain: environment.Auth0.Domain,
          clientId: environment.Auth0.Client_Id,
          authorizationParams: {
            redirect_uri: environment.Auth0.Redirect_URI
          }, 
          useRefreshTokens: environment.Auth0.Use_Refresh_Tokens
        })),

        {
          provide: APP_INITIALIZER,
          deps: [AuthenticationService],
          useFactory: (authService: AuthenticationService) => {
            return () => {
              authService.init();
              return;
            };
          },
          multi: true,
        },

        {
          // need to provide this separately from other api clients to use in auth service.
          provide: BelayMFACoreClient, 
          deps: [HttpClient, API_BASE_URL], 
          useFactory: MFAClientFactory
        },
        {
          // need to provide this separately from other api clients to use in auth service.
          provide: BelayAccountCoreClient, 
          deps: [HttpClient, API_BASE_URL], 
          useFactory: AccountClientFactory
        },
      ]
    }
  )
  .catch(err => console.error(err));