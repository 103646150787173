import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService, User } from '@auth0/auth0-angular';

@Component({
  selector: 'app-modules-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  user: User | null = null;

  constructor(public auth: AuthService){
    this.auth.user$.subscribe((userEvent) => this.HandleUserEvent(userEvent));
  }
  HandleUserEvent(userEvent: User | null | undefined): void {
    if(userEvent != null){
      this.user = userEvent;
    }else{
      this.user = null;
    }
  }
}