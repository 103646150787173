import { Injectable } from '@angular/core';
import { Router,  } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, IPageViewTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment.development';
import { DebuggerService, LogType } from './debugger.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private readonly angularPlugin = new AngularPlugin();
  private readonly appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.ApplicationInsights.ConnectionString,
      extensions: [this.angularPlugin],
      // Required for end-to-end traces
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAutoRouteTracking: true,
      // Domains where we don't want to send tracing headers, since it could lead to errors
      correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
    },
  });
  
  constructor(private readonly router: Router) {
    
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || [];
    });

    this.appInsights.loadAppInsights();
    DebuggerService.Log(LogType.ApplicationInsights, "TenantPickerComponent", "Application insights loaded => " + this.appInsights.config.instrumentationKey);
  }
  
  public init(): Promise<any> {
    DebuggerService.Log(LogType.ApplicationInsights, "TenantPickerComponent", "Application insights Init().");

    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }
}
