import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService, User } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment.development';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BelayIdentityTokenModel } from 'src/app/services/authentication/BelayJWTTokenModel';

@Component({
  selector: 'app-profile-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, RouterLink],
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent  implements OnInit {
  user: BelayIdentityTokenModel | null = null;
 
  constructor(private auth: AuthService, private  authService: AuthenticationService) {}

  ngOnInit(): void {
    this.user = this.authService.UserMetadata;
  }

  logout(): void {
    this.authService.Logout();
  }
}
