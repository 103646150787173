import { EventEmitter, Injectable, OnInit, Type } from '@angular/core';
import { MenuItem } from '../navigation/menu/MenuItem';
import { StartComponent } from 'src/app/modules/start/start.component';
import { NavigationEnd, Route, Router } from '@angular/router';
import { UsersComponent } from 'src/app/modules/administration/users/users.component';
import { CustomersComponent } from 'src/app/modules/administration/customers/customers.component';
import { AboutComponent } from 'src/app/modules/information/about/about.component';
import { filter } from 'rxjs';
import { ContactComponent } from '../modules/information/contact/contact.component';
import { InformationtComponent } from '../modules/information/informationt.component';
import { AccessDeniedComponent } from '../navigation/access-denied/access-denied.component';
import { authGuard } from '../auth.guard';
import { ProfileComponent } from '../modules/user/profile/profile.component';
import { LoginComponent } from '../modules/user/login/login.component';
import { MfaComponent } from '../modules/user/mfa/mfa.component';
import { DebuggerService, LogType } from './debugger.service';
import { PasswordlessComponent } from '../modules/user/passwordless/passwordless.component';

@Injectable()
export class NavigationService {
    
  // Complete list of navigation tree used for menu and routing.
  private static readonly _navigationTable = [
    new MenuItem("INFORMATION", InformationtComponent, "information" , "home", true, [
      new MenuItem("ABOUT", AboutComponent, "about", "favorite-icon", true, []),
      new MenuItem("CONTACT", ContactComponent, "contact", "coffee", true, [])
    ])
    ,
    new MenuItem("ADMINISTRATION", null, "administration", "computer", true, [
      new MenuItem("USERS", UsersComponent, "anvandare", "favorite-icon",  true, []),
      new MenuItem("CUSTOMERS", CustomersComponent, "kunder", "favorite-icon", true,  [])
    ])
    ,
    new MenuItem("BELAY", null, "https://belay.se", "circle",  true, [])
    ,
    new MenuItem("INFRIA", null, "https://infria.se", "square", true,  [])
    ,
    new MenuItem("PROFILE", ProfileComponent, "user", "person", true,  [
      new MenuItem("LOGIN", LoginComponent, "login", "circle",  true, []),
    ], true)
  ];



  private static _flatNavigationMenuList: MenuItem[] = [];

  private static _navchange: EventEmitter<NavigationEvent> = new EventEmitter();
  private static _runOnce = false;

  constructor(public router: Router){
    if(NavigationService._runOnce == false){
      NavigationService._runOnce = true;
      this.CreateRoutingEventMenuUpdater(this.router);
    }
  }

  private CreateRoutingEventMenuUpdater(router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        
        DebuggerService.Log(LogType.Navigation, "NavigationService", "CreateRoutingEventMenuUpdater -> " + JSON.stringify(event));

        let location = NavigationService._flatNavigationMenuList.find(function(item) {
          let url = "/" + item.FullURL;
          if (event.urlAfterRedirects.startsWith(url) && url.length > 1){
            return true;
          }else if (event.urlAfterRedirects == url){
            return true;
          }
          return false;
        });
        if (location != undefined) {
          this.Navigate(new NavigationEvent(location.TopParent, location));
        }else{
          // // no path mapped. 
          this.Navigate(new NavigationEvent(null, null));
        }
      }
    });
  }

  public Navigate(event: NavigationEvent) {
    DebuggerService.Log(LogType.Navigation, "NavigationService", "Navigation event: " + event.ActiveNode?.FullURL);
    NavigationService._navchange.emit(event);
  }

  public get NavigationEmitter(): EventEmitter<NavigationEvent>{
    return NavigationService._navchange;
  }

  public get items(): MenuItem[] {
    return NavigationService._navigationTable as MenuItem[];
  }

  public static  get NoLoginRoutes() : string[]{
    return ['/passwordless', '/access-denied'];
  }

  public static get RoutingTable() : Route[] {

    let routingTable = this.AddRoutingElements([], null, NavigationService._navigationTable);
    // add default routes and "catch all" route.
    routingTable.push({path: 'access-denied', component: AccessDeniedComponent});
    routingTable.push({path: 'passwordless', component: PasswordlessComponent});
    routingTable.push({path: 'mfa', component: MfaComponent});
    routingTable.push({path: '**', component: StartComponent});
    routingTable.push({path: '', component: StartComponent});

    DebuggerService.Log(LogType.Navigation, "NavigationService", "Routing table -> " + JSON.stringify(routingTable));
    return routingTable;
  }

  private static AddRoutingElements(current: Route[], parent: MenuItem | null, toAdd: MenuItem[], urlStack: string = ""): Route[]{
    toAdd.forEach((item) => {
      
      let path = (urlStack.length > 0) ? urlStack + "/" + item.RelativeURL : item.RelativeURL; 
      if(item.isInternal == true){
        current.push({path: path, component: item.Component as Type<any>, canActivate: (item.RequireLogin ? [authGuard]: undefined) });
        item.UpdateCompleteURL(path); 
      }else{
        item.UpdateCompleteURL(item.RelativeURL); 
      }
      item.UpdateParentMenuItem(parent);
      
      current = this.AddRoutingElements(current, item, item.SubPages, path);
      this._flatNavigationMenuList.push(item);
    });
    return current;
  }
}

export class NavigationEvent {
  constructor(private topNode: MenuItem | null, private activeNode: MenuItem | null){}

  public get TopNode(): MenuItem | null{
    return this.topNode;
  }

  public get ActiveNode(): MenuItem | null{
    return this.activeNode;
  }
}