import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule, NgFor } from '@angular/common';
import { NavigationService } from '../../../services/navigation.service';
import { MenuItem } from '../MenuItem';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { SideMenuItemComponent } from './menu-item/menu-item.component';
import { AuthenticationState } from 'src/app/services/authentication/AuthenticationState';

@Component({
  selector: 'app-navigation-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule, MatButtonModule, MatMenuModule, MatToolbarModule, 
    MatIconModule, CommonModule, MatSidenavModule, NgFor, RouterLink,
    TranslateModule, RouterLinkActive, SideMenuItemComponent
  ],
  providers: [NavigationService]
})
export class SideMenuComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  authState: AuthenticationState | null  = null;

  panelOpenState = false;
  menuItems: MenuItem[] = [];
  subUser: Subscription | null = null; 
  isAuthenticatedWithMFA = false;

  constructor(private navigationService: NavigationService, private authService: AuthenticationService) {}

  ngOnInit() { 
    this.menuItems = this.navigationService.items;

    this.subs.push(this.authService.AuthenticationState$.subscribe(authState => {
      this.authState = authState;
    }));
  } 

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
