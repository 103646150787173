import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-informationt',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './informationt.component.html',
  styleUrls: ['./informationt.component.scss']
})
export class InformationtComponent {

}
