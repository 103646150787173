import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { DebuggerService, LogType } from './services/debugger.service';

export const authGuard: CanActivateFn = (route, state): boolean | Promise<boolean> => {
  const authService: AuthenticationService = inject(AuthenticationService);
  DebuggerService.Log(LogType.Authentication, "AuthGuard", "Requested URL: " + state.url);

  return authService.AwaitedLoadingComplete.then(res => {
    DebuggerService.Log(LogType.Authentication, "AuthGuard.CanActivateFn", res ? "Is Authenticated" : "IS NOT AUTENTICATED.");
    if(res == false){
      authService.Login(state.url);
    }
    return res;
  })
};